<template>
  <v-form
    ref="resetPasswordForm"
    v-model="validChangePass"
    lazy-validation
    @submit.prevent="handleSubmit"
  >
    <h1 class="mb-4 text-center">Уведiть новый пароль</h1>
    <v-row>
      <v-col cols="12" class="pb-0">
        <v-text-field
          dense
          outlined
          v-model="password"
          ref="password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="show1 ? 'text' : 'password'"
          name="input-10-1"
          label="Пароль"
          hint="Мiнiмум 8 символiв"
          counter
          @click:append="show1 = !show1"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="pb-0 pt-0">
        <v-text-field
          dense
          outlined
          block
          v-model="verify"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min, rules.passwordMatch]"
          :type="show2 ? 'text' : 'password'"
          name="input-10-1"
          label="Пiдтвердити Пароль"
          counter
          @click:append="show2 = !show2"
        ></v-text-field>
      </v-col>
      <v-col class="d-flex justify-center pa-0" cols="12">
        <v-btn
          type="submit"
          :loading="loading"
          :disabled="!validChangePass"
          color="success"
        >Вiдновити
        </v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: 'ResetPasswordForm',
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      valid: true,

      validChangePass: false,

      password: '',
      verify: '',

      show1: false,
      show2: false,
      rules: {
        required: (value) => !!value || "Обов'язковe поле",
        min: (v) => (v && v.length >= 8) || 'Мiнiмум 8 символiв',
        passwordMatch: () =>
          this.password === this.verify || 'Паролi повиннi збiгатися'
      }
    }
  },
  mounted () {
    this.focusInput('password')
  },
  methods: {
    handleSubmit () {
      this.$refs.resetPasswordForm.validate() &&
      this.$emit('onSubmit', { password: this.password, verify: this.verify })
    },
    focusInput (name) {
      this.$refs[name].focus()
    }
  }
}
</script>
